import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29df7e50 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _456bf870 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e926a9b2 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _60e37bca = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _a7ef70d6 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _f6b4ce4a = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _681208aa = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _fdf94498 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _5f9d1932 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _00082713 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _4903956d = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _28f45db8 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _0074cd35 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _69f4d366 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _3a3dd68e = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _4efd0194 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _3ce36f9e = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _71c432d8 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _256ae89e = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _1309e322 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _29df7e50,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _456bf870,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _e926a9b2,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _60e37bca,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _a7ef70d6,
    name: "receipt"
  }, {
    path: "/search",
    component: _f6b4ce4a,
    name: "search"
  }, {
    path: "/search-booking",
    component: _681208aa,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _fdf94498,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _5f9d1932,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _00082713,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _4903956d,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _28f45db8,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _0074cd35,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _69f4d366,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _3a3dd68e,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _4efd0194,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _3ce36f9e,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _71c432d8,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _256ae89e,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _1309e322,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
